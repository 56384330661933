import request from '@/common/axios';

// 优惠券管理列表
export function fetchCouponList(data) {
    return request({
        url: '/web/admin/coupon/adminCouponPage',
        method: 'POST',
        data: {
           ...data
        }
    });
}

// 优惠券上下架
export function couponPutaway(data) {
    return request({
        url: '/web/admin/coupon/updateCouponStatus',
        method: 'POST',
        data: data
    });
}

// 修改优惠券
export function updateCoupon(data) {
    return request({
        url: '/web/admin/coupon/updateCoupon',
        method: 'POST',
        data: data
    });
}

// 添加优惠券
export function addCoupon(data) {
    return request({
        url: '/web/admin/coupon/addCoupon',
        method: 'POST',
        data: data
    });
}