<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <eb-filter-user-list v-model="query.userId" @change="getList(1)">
        </eb-filter-user-list>
      </el-col>

      <el-col :span="4">
        <el-select
          placeholder="是否使用"
          v-model="this.query.status"
          @change="getList(1)"
        >
          <el-option :value="-1" label="优惠券是否使用"></el-option>
          <el-option
            v-for="(item, index) in this.const.IS_USE"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button
          type="primary"
          @click="showAddModal"
          v-auth="this.per.ADD_USER_COUPON"
          >发放优惠券</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="用户ID" prop="userId"></el-table-column>
      <el-table-column label="优惠券金额（元）" prop="couponPrice">
        <template #default="scope">
          <span>{{ scope.row.couponPrice / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最低使用金额（元）" prop="couponRule">
        <template #default="scope">
          <span>{{ scope.row.couponRule / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="是否使用" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.IS_USE"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="过期时间" prop="endTime"></el-table-column>

      <el-table-column label="获得时间" prop="createAt"></el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :ref="MODAL_KEY.EDIT_MODAL"
      :modalData="modalData"
      @submit="doAddData"
    ></EditModal>
  </div>
</template>

<script>
import { fetchUserCoupont, addUserCoupont } from "@/api/user";

import moment from "moment";
import EditModal from "./components/EditModal.vue";
export default {
  components: { EditModal },
  data() {
    return {
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      
      dataSource: [],
      userList: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      expire: -1,
      query: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    showAddModal() {
      this.modalData = {};
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    doAddData(data) {
      addUserCoupont(data).then((res) => {
        this.$message.success("添加成功");
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    changeDate(type) {
      // let curDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      // if (type == 1) {
      //   this.query.endTime;
      // } else if (type == 2) {
      //   this.query.endTimeGt = curDate;
      //   this.query.endTimeLt = "";
      // }
      // this.expire = -1;
      // this.getList(1);
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserCoupont({ ...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },
};
</script>
