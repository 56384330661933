<template>
  <el-dialog
    title="发放优惠券"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="手机号码" prop="phone">
        <el-input
          placeholder="请输入手机号码"
          v-model="modalData.phone"
        ></el-input>
      </el-form-item>

      <el-form-item label="优惠券" prop="couponId">
        <el-select placeholder="请选择优惠券" v-model="modalData.couponId">
          <el-option
            v-for="(item, index) in couponList"
            :key="index"
            :label="item.couponName"
            :value="item.id"
          >
            <span style="float: left">{{ item.couponName }}</span>
            <span
              style="
                float: right;
                color: var(--el-text-color-secondary);
                font-size: 13px;
              "
              >金额：{{ item.couponPrice /100 }};有效期：{{item.days}}; 限额：{{ item.couponRule / 100 }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { validatorForm } from "@/common/util";
import { fetchCouponList } from "@/api/coupon";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      couponList: [],
      formName: "form",
      ruleValidate: {
        couponId: [
          {
            required: true,
            message: "请选择优惠券能为空",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "change",
          },
          { validator: validatorForm.phone },
        ],
      },
    };
  },
  methods: {
    getCouponList() {
      fetchCouponList({
          status: 1,
        pageIndex: 1,
        pageSize: 100,
      }).then((res) => {
        console.log(res.data.data.records,'res.data.data.records');
        this.couponList = res.data.data.records;
      });
    },
    submit() {
      let modalData = this.modalData;

      let data = {
        phone: modalData.phone,
        couponId: modalData.couponId,
      };

      this.validateForm().then((res) => {
        let obj = this.deepClone(data);
        this.$emit("submit", obj);
      });
    },
  },

  mounted() {
    this.getCouponList();
  },
};
</script>
